import React from 'react';
import { Box, Typography } from '@mui/material';

const LabeledCustomItem = ({ label, value }) => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      mb: 1, // margin bottom 1 spacing unit
    }}>
      <Typography variant="body2" sx={{ 
        fontSize: { xs: '0.595rem', sm: '0.595rem', md: '0.595rem', lg: '0.6rem', xl: '0.675rem' },
        fontWeight: 'lighter',
        mb: 0.3, // margin bottom 0.3 spacing units
      }}>
        {label}
      </Typography>
      <Typography variant="body1" sx={{ fontSize: { xs: '0.85rem', sm: '0.85rem', md: '0.85rem', lg: '0.9rem', xl: '1em'}}}>
        {value}
      </Typography>
    </Box>
  );
};

export default LabeledCustomItem;
