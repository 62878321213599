

import React, { useState, useCallback, useEffect } from "react";
import { Create, SimpleForm, TextInput, Toolbar, SaveButton,  
        TextField,  SelectInput, useNotify, Button, ReferenceInput,
        useRegisterMutationMiddleware, useDataProvider, ReferenceField, Labeled,
        SelectArrayInput, useRefresh, email,
    } from 'react-admin';
import {  Grid, Select } from '@mui/material';
import { Sucursales } from "../../utils";
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { OrdenesPendientesShow } from "../components/OrdenesPendientesShow";
import { EditableDatagridCustom } from "../components/EditableDatagridCustom";
import CircularProgress from '@mui/material/CircularProgress';
import { TotalesComponentFactura } from "./components/TotalesComponentFactura";
import { TiempoPago } from '../../utils'
import RemisionArrayInput from "./components/RemisionArrayInput";
import RemisionesShow from "./components/RemisionesShow";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useFormContext } from 'react-hook-form';
import  CreateRole  from "./components/EmailArrayInput";
import { Dialog, DialogContent, DialogActions, Button as MuiButton, TextField as TextFieldMui } from '@mui/material';
import { useCreateSuggestionContext } from 'react-admin';
import { formatCurrencyString } from "../../utils";
import { testFact, pdfResponseTest } from "./components/testFact";



const CustomToolbar = () => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const { getValues } = useFormContext();
    const [loadingPreview, setLoadingPreview] = useState(false);
    const [isDraft, setIsDraft] = useState(false);

    const middleware = useCallback(async (resource, params, options, next) => {
        let { data } = params;
        let newParams = {};
        console.log('DATA INSIDE MIDDLEWARE', data);
        try {
            console.log("Starting Alegra creation");
            const alegraJson = getAlegraJson(data, true, isDraft);
            const response = await dataProvider.create('alegraInvoices', { data: alegraJson });
            const pdfResponse =  await dataProvider.getOne('alegraInvoices', { id: response.data.id });
            // const response = testFact;// For testing
            // const pdfResponse = pdfResponseTest;//// For testing
    
            if (response?.data) {
                notify('Factura creada en Alegra', { type: 'success' }, { smart_count: 1 });
                console.log('FACTURA CREADA', response);
            }
            if (pdfResponse?.data) {
                notify('Factura PDF ok', { type: 'success' }, { smart_count: 1 });
                console.log('PDF RESPONSE', pdfResponse);
            }
    
            const { productFact, clientData, ordenes, ...dataNew } = data;
            newParams = {
                ...params,
                data: {
                    ...dataNew,
                    alegraId: response.data.id,
                    alegraNumeracion: response.data.numberTemplate.fullNumber,
                    pdf: `https://app.alegra.com/invoice/print/id/${response.data.id}`,
                    pdfAlt: !isDraft ? pdfResponse.data.pdf : '',
                    url: `https://app.alegra.com/invoice/view/id/${response.data.id}`,
                    estado: response.data.status,
                    fechaCrea: new Date().toISOString(),
                    fechaVence: new Date(response.data.dueDate).toISOString(),
                    usuarioID: localStorage.getItem('user'),
                    valorPendiente: response.data.balance,
                    stamp: {...response.data.stamp, date:new Date(response.data.stamp.date+"-05:00")}
                },
            };
            console.log("newParams", newParams)
        } catch (error) {
            if (error.message === 'El id del ítem es un campo obligatorio') {
                notify('Error: Caja No Seleccionada', { type: 'error' }, { smart_count: 2 });
            } else {
                notify(`Error: ${error.message}`, { type: 'error' }, { smart_count: 2 });
            }
            return;
        }
    
        let factNew;
        try {
            console.log("Starting Factura GI Creation");
            factNew = await next(resource, newParams, options);
            console.log("FACTURA GI RESPONSE:", factNew);
        } catch (error) {
            console.error('ERROR', error);
            notify(`Error ${error.message}`, { type: 'error' }, { smart_count: 4 });
            return;
        }
    
        try {
            const testing = false; // Set to true if you don't want to update the sqlOrdenOut table
            console.log("Starting SQL UPDATES");
            if (!testing) {
                const facturaNumber = newParams.data.alegraNumeracion.match(/\d+/)[0];
                const updatePromises = [];
                
                if (data?.ordenes) {
                    updatePromises.push(
                        ...data.ordenes.map((orden) =>
                            dataProvider.update('sqlOrdenFacturada', {
                                filter: { numero: orden.numero, versionop: orden.version, factura: facturaNumber },
                            })
                        )
                    );
                }
                if (data?.productFact?.items) {
                    updatePromises.push(
                        ...data.productFact.items.map((product) =>
                            dataProvider.update('sqlOrdenFacturada', {
                                filter: { numero: product.orden, versionop: product.version, factura: facturaNumber },
                            })
                        )
                    );
                }
    
                await Promise.all(updatePromises);
                if (data.itemsFact?.length > 0) {
                    discountFromStock(data.itemsFact);
                }
            }
        } catch (error) {
            console.error('ERROR', error);
            notify(`Error ${error.message}`, { type: 'error' }, { smart_count: 1 });
        }
    
        try {
    
            if (data.itemsFact?.length > 0) {
                console.log("STARTING ITEMS FACT CREATES");
                const res = await Promise.all(
                    newParams.data.itemsFact.map((item) => {
                        const newItem = {
                            ...item,
                            vendedorID: factNew.vendedorID,
                            ciudad: factNew.ciudadId,
                            facturaID: factNew.id,
                            facturaNro: factNew.alegraNumeracion,
                            clienteID: factNew.clienteId,
                            clienteName: factNew.clienteName,
                        };
                        return dataProvider.create('productFacts', { data: newItem });
                    })
                );
            }
    
            if (data.productFact?.items?.length > 0) {
                console.log("Starting PRODUCTFACT UPDATES");
                const res2 = await Promise.all(
                    data.productFact.items.map((item) => {
                        const { remision, createdAt, updatedAt, factura, cotiza, ...rest } = item;
                        const newItem = {
                            ...rest,
                            vendedorID: factNew.vendedorID,
                            ciudad: factNew.ciudadId,
                            facturaID: factNew.id,
                            facturaNro: factNew.alegraNumeracion,
                            clienteID: factNew.clienteId,
                            clienteName: factNew.clienteName,
                        };
                        return dataProvider.update('productFacts', { data: newItem });
                    })
                );
            }
        } catch (error) {
            console.error('ERROR', error);
            notify(`Error ${error.message}`, { type: 'error' }, { smart_count: 3 });
        }
    
        try {
            if (data.productFact?.items?.length > 0) {
                console.log("Starting REMISION UPDATES");
                const remisionIds = [...new Set(data.productFact.items.map((item) => item.remisionID))];
                const remisiones = await dataProvider.getMany('remissions', { ids: remisionIds });
    
                const remisionUpdatePromises = remisiones.data.map((remision) => {
                    const { factura, productFact, ...remisionData } = remision;
                    return dataProvider
                        .update('remissions', {
                            id: remisionData.id,
                            data: { ...remisionData, estado: 'Facturada', facturaID: factNew.id },
                        })
                        .then(() => {
                            notify('Remisiones Actualizadas', { type: 'info' }, { smart_count: 4 });
                        });
                });
    
                await Promise.all(remisionUpdatePromises);
            }
        } catch (error) {
            console.error('ERROR', error);
            notify(`Error ${error.message}`, { type: 'error' }, { smart_count: 4 });
        }
    
        try {
            if (data.emails) {
                console.log("Starting EMAIL SENDING", newParams.data.alegraId, data.emails);
                const emails = data.emails.map((email) => email.id);
                await dataProvider.email('alegraInvoices', {
                    id: newParams.data.alegraId,
                    emails: [...data.emails],
                });
            }
        } catch (error) {
            console.error('ERROR', error);
            notify(`Error ${error.message}`, { type: 'error' }, { smart_count: 5 });
        }
    }, []);
    

    const discountFromStock = async (items) => {
        const cajas = items.reduce((acc, item) => {
            const found = acc?.find(it => it.caja === item.caja);
            if (found) {
                if (found.area > 0){
                    found.area += item.area;
                }
            } else {
                acc.push({ caja: item.caja, area: item.area });
            }
            return acc;
        }, []);
        for (const caja of cajas) {
            if(caja === null || caja.area<=0 || caja.caja === undefined) continue;
            const stock = await dataProvider.getOne('stocks', { id: caja.caja });
            const newStock = {
                ...stock.data,
                areaRestante: stock.data.areaRestante - caja.area,
            }
            delete newStock.retales
            await dataProvider.update('stocks', { id: caja.caja, data: newStock });
        }
    }
    
    const handlePreview = async () => {
        setLoadingPreview(true);
        const values = getValues();
        ////console.log('VALUES', values);
        const alegraJson =  getAlegraJson(values, false);
        const response = await dataProvider.preview('alegraInvoices', { data: alegraJson })
        ////console.log('RESPONSE', response);
        ////console.log('PDF URL', await response.data.pdfUrl);
        window.open(await response.data.pdfUrl, '_blank');
        setLoadingPreview(false);
    }

    const getAlegraJson =  (values, stamp=false, isDraft) => {
        //const values = getValues(); 
        ////console.log('VALUES', values);
        const customer = values.clientData;
        const diasPago = TiempoPago.find(tp => tp.id === customer.datosFacturacion.formaPago);
        ////console.log('Dias Pago', diasPago);
        const dueDateEnd = new Date(new Date().setDate(new Date().getDate() + diasPago.value));

        //console.log('BEFORE ODCS', dueDateEnd);
        let odcsArr = values?.itemsFact?.map(item => item.odc?.trim()) || [];
        //console.log('ODCS', odcsArr);
        const odcArr2 = values?.productFact?.items?.map(item => item.odc?.trim()) || [];
        
        if(odcsArr.length > 0 && odcArr2.length > 0){
            odcsArr = [...odcsArr, ...odcArr2];
        }else if(odcsArr.length === 0 && odcArr2.length > 0){
            odcsArr = odcArr2;
        }


        //console.log('ODCS2', odcsArr);
        const odcs = [...new Set(odcsArr)].toString().replaceAll(',', '-');
        //console.log('ODCS3', odcs);

        const productosRemisionados = values.productFact;
        const remisionGroup = productosRemisionados && productosRemisionados?.items?.length > 0 
        ? productosRemisionados.items?.reduce((r, a) => {
            r[a.remisionNro] = [...r[a.remisionNro] || [], a];
            return r;
        }, {}) 
        : {};
        const alegraRemisionIds = Object.keys(remisionGroup).map(remision => {
            const data = remisionGroup[remision] || []; 
            return(
                data[0].remision.alegraId   
            )
        }, []);

        ///
        let anotaciones = '';
        if(values.observaciones){
            const title = 'Nota: ';
            const value = values.observaciones;
            const emptyChar = 120 - value.length - title.length;
            anotaciones += `${title}${value} ${' '.repeat(emptyChar)}`
        }
        if(Object.keys(remisionGroup).length > 0 ){
            const title = 'Remisiones Asociadas: '
            const value = Object.keys(remisionGroup).toString().replaceAll(',', ',  ');
            if (value.length > 38){ 
                const value1 = value.substring(0, 38);
                const value2 = value.substring(38, value.length);
                const emptyChar = 120 - value1.length - title.length;
                anotaciones += `${title}${value1} ${' '.repeat(emptyChar)}`
                anotaciones += `${value2} ${' '.repeat(120 - value2.length)}`
            }else{
                const emptyChar = 120 - value.length - title.length;
                anotaciones += `${title}${value} ${' '.repeat(emptyChar)}`
            }
        }
        if(odcs.length > 0 ){
            const title = 'Orden de Compra: ';
            const value = odcs;
            const emptyChar = 120 - value.length - title.length;
            anotaciones += `${title}${value} ${' '.repeat(emptyChar)}`
        }
        if(values.retenciones.length > 0){
            const title = 'Total Menos Retenciones: ';
            const value = formatCurrencyString(values.totalSinRetenciones,1)
            const emptyChar = 120 - value.length - title.length;
            anotaciones += `${title}${value} ${' '.repeat(emptyChar)}`
        }


        const alegraJson = {
            paymentForm: customer.formaPago==='De_Contado' ? "CASH" : "CREDIT",
            paymentMethod: 'BANK_DEPOSIT',
            type:'NATIONAL', //MODIFY HERE WHEN CREATING INTERNATIONAL INVOICES
            stamp: {generateStamp: stamp},
            operationType: 'STANDARD',
            purchaseOrderNumber: odcs ? odcs : '',
            status: isDraft?'draft':'open', // draft or open if wanted to add payments
            numberTemplate: {id: '23'},//change this when creating international invoices
            date: new Date(),
            dueDate: dueDateEnd,
            anotation:  anotaciones,
            client: { id: values.alegraClienteId },
            seller: values.vendedorAlegraId ? { id: values.vendedorAlegraId } : undefined,
            items: values.itemsFact.map(item => {
                let descripcion = '';
                if(item.ancho>0&&item.largo>0 && item.area > 0){
                    descripcion =  `${item.descripcion}\n${item.ancho} x ${item.largo}   Cant: ${item.cantidad}`
                    if(item.odc && item.odc?.trim().length > 0){
                        descripcion = `${descripcion} - ODC:${item.odc}`
                }} else {
                    descripcion = item.descripcion
                    if(item.odc){
                        descripcion = `${descripcion} - ODC:${item.odc}`
                    }
                }
                return({
                id: item.alegraItemId,
                reference: item.ordenVersion,
                description: descripcion,
                quantity: item.area > 0 ? item.area : item.cantidad,
                price: item.valorUnitario,
                tax: [{ id: item.iva ? 3 : 0 }],
            })}),
            retentions: values.retenciones.map(retencion => {
                return({
                    id: retencion.id,
                    amount: retencion.type === 'IVA' ? (retencion.percentage/100) * values.iva : (retencion.percentage/100) * values.subtotal,
                })
            }),
            remissions: alegraRemisionIds,
            costCenter: {id: Sucursales.find(sucursal => sucursal.id === values.ciudadId).costCenterId},
            warehouse:  {id: Sucursales.find(sucursal => sucursal.id === values.ciudadId).alegraID},

        };
        console.log('ALEGRA JSON', alegraJson);
        //return  dataProvider.create('alegraInvoices', { data: alegraJson })
        return alegraJson;
    }
    
    useRegisterMutationMiddleware(middleware);
    
    return (
        <Toolbar>
            <Grid container spacing={1}>
                {/* <Grid item md={2}>
                    <SaveButton key='borrador' label="Guardar Borrador"  variant="outlined" alwaysEnable />            
                </Grid>     */}
                <Grid item md={2}>
                    <Button
                        key={'preview'}
                        label="Vista Previa"
                        sx={{ padding: '8px 16px'}}
                        startIcon={loadingPreview ? <CircularProgress size={20} />:<VisibilityIcon/>}
                        onClick={handlePreview}
                        variant="outlined"
                    />   
                </Grid>    
                <Grid item md={10} >
                    <SaveButton
                        key={'emitir'}
                        label="Emtir Factura"
                        icon={<SendAndArchiveIcon />}
                        alwaysEnable
                        sx={{float: 'right'}}
                    />
                </Grid>   
            </Grid> 
        </Toolbar>
    );
};





const FacturaCreate = () => {
    const [ refreshDatagrid, setRefreshDatagrid ] = useState();
    
    const refetchData = (v) => {
        setRefreshDatagrid(prev => !prev);
    }
        
    return (
        <Create  redirect='show' >
            <SimpleForm 
                toolbar={<CustomToolbar/>}
            >
                <Grid container spacing={1}>
                    <Grid container direction="row" justifyContent={'space-evenly'} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Labeled>
                                <ReferenceField source = 'clienteId' reference='clientes'>
                                    <TextField source="razonSocial" label="Cliente"sx={{padding:'10px', fontSize: '25px'}} />
                                </ReferenceField>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <RemisionArrayInput refreshDatagrid={()=>setRefreshDatagrid((prev)=>!prev)}/>
                        </Grid> 
                        <Grid item xs={12} md={5}>
                            <EmailArrayInput/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SelectInput source="ciudadId" label="Ciudad"  choices={Sucursales} optionText='name' fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <ReferenceInput source = 'vendedorID' reference='usuarios'>
                                <SelectInput optionText={(r)=>`${r.nombres} ${r.apellidos}`} label="Vendedor" fullWidth/>
                            </ReferenceInput>
                        </Grid> 
                        <Grid item xs={12} md={4}>
                                <SelectInput source='formaPago' choices={TiempoPago} label="Tiempo Pago" fullWidth/>
                        </Grid> 
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <EditableDatagridCustom refreshDatagrid={refreshDatagrid}/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <RemisionesShow/>
                    </Grid>
                    <TotalesComponentFactura changed={refetchData} type='factura'/>
                </Grid>
            </SimpleForm>
            <OrdenesPendientesShow/>
        </Create>
    );
};






const EmailArrayInput = (props) => {
    const { getValues, setValue } = useFormContext();
    const emailsString = getValues('clientData.datosFacturacion.emailFE').replaceAll(' ', '');
    //console.log('EMAILS', emailsString)
    const [emails, setEmails] = useState([]);
    const [openDialog , setOpenDialog] = useState(false);
    useEffect(() => {
        if (emailsString) {
            const emailArray = emailsString.split(',');
            if (emailArray.length === 1 && emailArray[0] === '') {
                setEmails([]);
                return;
            }
            const formattedEmails = emailArray.map(email => ({
                id:email.trim(),
                name:email.trim()
            }));
            setEmails(formattedEmails);
            //console.log(formattedEmails)
        }
    }, [emailsString]);

    return (
        <>
            <SelectArrayInput
                source="emails"
                label="Emails"
                fullWidth
                choices={emails}
                create={<CreateEmailDialog />}
                createLabel="Agregar Email"
            />
            {/* //<CreateEmailDialog open={openDialog} setOpen={setOpenDialog}/> */}

        </>
        
    );
};

const CreateEmailDialog = (props) => {
    const { onCancel, onCreate } = useCreateSuggestionContext();
    const [values2, setValues2] = React.useState('');
    const [error, setError] = React.useState(false);
    const [touched, setTouched] = React.useState(false); // New state to track if the user has interacted with the input
    const { setValue, getValues } = useFormContext();
    const existingEmails = getValues('clientData.datosFacturacion.emailFE');
    const refresh = useRefresh();

    const handleEmailChange = event => {
        setValues2(event.target.value);
        if (touched && error) {
            // Clear error if user starts typing again after an error
            setError(false);
        }
    };

    const handleEmailBlur = () => {
        if (!touched) setTouched(true);
        validateEmail(values2);
    };

    const validateEmail = (email) => {
        if (!touched) return; // Skip validation if the field hasn't been interacted with
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        setError(!isValidEmail);
    };

    const handleSubmit = event => {
        event.preventDefault();
        if (!touched) setTouched(true);
        validateEmail(values2);

        if (error || !values2) return; // Prevent submission if there's an error or no value

        const newOption = { id: values2, name: values2 };
        setValue('clientData.datosFacturacion.emailFE', `${existingEmails},${values2}`);
        setValues2('');
        onCreate(newOption);
        refresh();
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextFieldMui
                        error={touched && error} // Only show error if the field has been touched
                        label="Email"
                        value={values2}
                        onChange={handleEmailChange}
                        onBlur={handleEmailBlur}
                        autoFocus
                        helperText={touched && error ? "Please enter a valid email address" : ""}
                    />
                </DialogContent>
                <DialogActions>
                    <MuiButton type="submit" disabled={error || !values2}>
                        Save
                    </MuiButton>
                    <MuiButton onClick={onCancel}>Cancel</MuiButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};




export default FacturaCreate;
