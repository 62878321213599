import React, {useCallback, useState, useEffect, useRef} from 'react';
import { List, Datagrid, TextField, DateField, ReferenceField, useListContext, SearchInput, TopToolbar, BulkUpdateButton,
    useRecordContext, useNotify, Pagination, useDataProvider, useRefresh, ChipField, WrapperField,
    UrlField, useUnselectAll, FilterList, FilterLiveSearch,  
    NumberField, CreateButton, useGetList,
    useRedirect,
    AutocompleteInput,
    FunctionField
} from 'react-admin';

import ImportInvoice from './components/ImportInvoice';
import CircularProgress from '@mui/material/CircularProgress';
import { Autocomplete, Card, CardContent } from '@mui/material';
import { IconButton } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import DownloadingIcon from '@mui/icons-material/Downloading';
import { formatCurrencyString } from '../../utils';
import {Button, Stack } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import BlockIcon from '@mui/icons-material/Block';
import { DeleteRemision } from '../components/DeleteRemision';
import { generatePDF } from '../functions/generateRemisionPDF';
import AlegraIcon from "../components/alegraIcon";
import EditIcon from '@mui/icons-material/Edit';
import StatusFilter from './components/StatusFilter';
import CheckIcon from '@mui/icons-material/Check';
import { apply } from 'pdfmake/build/pdfmake';



//const filterToQuery = searchText => ({ razonSocial: {wildcard: `*${searchText}*`}});

const RemisionFilters = [
    //<TextInput label="Numero" source="alegraNumeracion.wildcard" alwaysOn format={v=>v?.replaceAll('*', '')||''}/>,
    <SearchInput source='q' alwaysOn />,
    <StatusFilter   source='and' label= "Estado" alwaysOn />

]

//FIX TOGGLE BUTTON TO BE ABLE TO UNSELECT
const ListPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} />;


const RemisionList = () => (
    <List   queryOptions={{meta:{searchable:'true', filterable: ['clienteName', 'alegraNumeracion.wildcard']}}} 
            filters= {RemisionFilters} 
            sort={{field: 'fechaCrea', order: 'DESC'}} 
            actions={<ListActions/>} 
            pagination = {<ListPagination />} 
            perPage={50} 
            debounce={1000}
            title={'Remisiones'} >

        <Datagrid  bulkActionButtons={<ListBulkActions/>}>
            <TextField source="alegraNumeracion" label='Número'/>
            <TextField source="clienteName" label='Cliente' />
            <DateField source="fechaCrea" label='Fecha Creación'/>
            <ReferenceField source="facturaID" reference="facturas" label="Factura" link='show'>
                <TextField source="alegraNumeracion" sx={{ fontWeight:'bold'}}/>
            </ReferenceField>
            <ReferenceField source="vendedorID" reference="usuarios" label="Vendedor"  >
                <FunctionField source="nombre" render={(r)=>`${r.nombres} ${r.apellidos}`} />
            </ReferenceField>
            <StatusField />
            <NumberField source="total" locales="es-CO" options={{ style: 'currency', currency: 'COP',maximumFractionDigits: 0 }} />
            <WrapperField label="Acciones">
                <AlegraButton />
                <ImprimirButton   />
                <EditButtonIcon />
                <DeleteRemision type='icon' />
            </WrapperField>
        </Datagrid>
    </List>
);
const AlegraButton = () => {
    const record = useRecordContext();
    return(
        <IconButton
            variant='outlined'
            onClick={() => {
                window.open(record.url, '_blank');
            }}
        >
            <AlegraIcon/>
        </IconButton>
    );
}

const EditButtonIcon = () => {
    const redirect = useRedirect();
    const record = useRecordContext();

    return(
        <IconButton
            variant='outlined'
            onClick={() => {
                redirect(`/remissions/${record.id}`);
            }}
        >
            <EditIcon/>
        </IconButton>
    );
}

const StatusField = () => {       
    const record = useRecordContext();
    if (record.estado === "Facturada") {
        return <ChipField source="estado" color="success" variant='outlined' />;
    }
    if (record.estado === "Editada") {
        return <ChipField source="estado"  color="info"   variant='outlined' />;
    }
    if (record.estado === "Anulada") {
        return <ChipField source="estado"  color="error" variant='outlined'/>;
    }
    return <ChipField source="estado"  color="primary" variant='outlined'/>;
};

const ImprimirButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false); 
    
    //console.log('RECORD', record)

    return(
    <IconButton
        variant='outlined'
        onClick={async () => {
            setLoading(true);
            const res = await dataProvider.getOne('clientes', { id: record.clienteId })
            // set client info to generate PDF
            
            if(res.data){
                const client = {name : res.data.razonSocial, identification: res.data.nit_cedula, address:{address: res.data.direccion}, phonePrimary: res.data.telefono1}

                setLoading(false);
                generatePDF(record, client);
                }
            }

        }
    >{

        loading ?  <DownloadingIcon/> :
        <PrintIcon/>
    }
    </IconButton>
);
}

const ListActions = () => {
    //const { data } = useListContext();
    //console.log('DATA', data);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const [updating, setUpdating] = useState(false);

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const handleUpdate = async () => {
        setUpdating(true);
        let allData = [];
        let page = 1;
        const BATCH_SIZE = 149;
        const DELAY_BETWEEN_BATCHES = 60000; // 1 minute
    
        while (true) {
            const limitCall = 500;
            const data = await dataProvider.getList('remissions', {
                filter: { or: [{ estado: { eq: "Emitida" } }, { estado: { eq: "Editada" } }] },
                pagination: { perPage: limitCall, page: page },
                sort: { field: 'createdAt', order: "ASC" },
                meta: { searchable: 'true' }
            });
            const currentData = data.data;
            allData = allData.concat(currentData);
    
            // If the number of records is less than 999, we've retrieved all available records
            if (currentData.length < limitCall) break;
    
            page += 1; // Increment to the next page if more records are expected
        }
    
        for (let i = 0; i < allData.length; i += BATCH_SIZE) {
            const batch = allData.slice(i, i + BATCH_SIZE);
    
            const statusPromises = batch.map(item =>
                dataProvider.getOne('alegraRemissions', { id: item.alegraId })
                    .then(response => {
                        const status = response?.data.status;
                        if (status === "closed") {
                            item.estado = "Facturada";
                        } else if (status === "void") {
                            item.estado = "Anulada";
                        } else {
                            console.log('No se debe actualizar el estado', item.alegraNumeracion);
                        }
                        return item; // Return the updated item
                    })
                    .catch(error => {
                        console.error('ERROR', error);
                        notify('Error: no se pudo actualizar el estado', { type: 'error' });
                        return item; // Return the item even if the request fails
                    })
            );
    
            // Wait for all status updates to complete for this batch
            const updatedBatch = await Promise.all(statusPromises);
    
            const updatePromises = updatedBatch.map(item => {
                if (item.estado === "Facturada" || item.estado === "Anulada") {
                    const { productFact, factura, ...updatedItem } = item;
                    return dataProvider.update('remissions', {
                        id: updatedItem.id,
                        data: { ...updatedItem },
                        previousData: updatedItem,
                    });
                }
                return null;
            }).filter(Boolean); // Filter out null values where no update is needed
    
            await Promise.all(updatePromises);
    
            // If there are more batches to process, wait before the next batch
            if (i + BATCH_SIZE < allData.length) {
                console.log(`Waiting ${DELAY_BETWEEN_BATCHES / 1000} seconds before next batch...`);
                await delay(DELAY_BETWEEN_BATCHES);
            }
        }
        setUpdating(false);
        refresh(); // Refresh UI after all updates are done
        return allData; 
    };
    
    return(
    <TopToolbar>
        <Button startIcon={updating ? <CircularProgress size={20} />:<SyncIcon/>} onClick={handleUpdate} size='small'>
            Actualizar Estado
        </Button>
    </TopToolbar>
);

}

const ListBulkActions = () => {
    const { data, selectedIds} = useListContext();  
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const [textWarn, setTextWarn] = useState('');
    const [ textRemision, setTextRemision] = useState('');
    const [textWarn2, setTextWarn2] = useState('');
    const [ textRemision2, setTextRemision2] = useState('');
    const [initialState, setInitialState] = useState({});
    const selectedData = data.filter(record => selectedIds.includes(record.id));
    //  console.log('SELECTED DATA', selectedData);
    const isSameCliente = selectedData.length > 0 && selectedData.every(record => record.clienteId === selectedData[0].clienteId);
    const isSameVendedor = selectedData.length > 0 && selectedData.every(record => record.vendedorID === selectedData[0].vendedorID);
    const areInvoiced = selectedData.length > 0 && selectedData.every(record => record.estado === 'Facturada');
    const areAllEmitidas = selectedData.length > 0 && selectedData.every(record => record.estado === 'Emitida');
    //const [someInvoiced, setSomeInvoiced] = useState(false);

    //const previousSelectedLength = useRef(null); 

    // const updateStatuses = useCallback(async () => {
    //     console.log('-------------------------->updateStatuses CALLED');
    //     if (!areAllEmitidas || selectedData.length > 150 || !isSameCliente || !isSameVendedor) return;

    //     let isInvoiced = false;

    //     try {
    //         await Promise.all(
    //             selectedData.map(async (record) => {
    //                 const { id, alegraId, estado } = record;

    //                 if (estado !== 'Facturada') {
    //                     const res = await dataProvider.getOne('alegraRemissions', { id: alegraId });
    //                     console.log('ALEGRA REMISION', res);
    //                     if (res.data && res.data.status === 'closed') {
    //                         isInvoiced = true;
    //                         delete record.factura;
    //                         delete record.productFact;
    //                         const updatedItem = { ...record, estado: 'Facturada' };

    //                         await dataProvider.update('remissions', {
    //                             id,
    //                             data: updatedItem,
    //                             previousData: record,
    //                         });
    //                     }
    //                 }
    //             })
    //         );

    //         if (isInvoiced) {
    //             refresh();
    //             notify('Estado de remisiones actualizado', { type: 'info' });
    //             setSomeInvoiced(true);
    //         }
    //     } catch (error) {
    //         console.error('Error updating remissions:', error);
    //     }
    // }, [areAllEmitidas, selectedData, isSameCliente, isSameVendedor, dataProvider, refresh, notify]);

    // useEffect(() => {
    //     if (previousSelectedLength.current !== selectedData.length) {
    //         // Run the effect if the length changes
    //         previousSelectedLength.current = selectedData.length; // Update the ref to current length
    //         updateStatuses(); // Run the updateStatuses function
    //     }
    // }, [selectedData.length, updateStatuses]);

    useEffect(() => {
        const remisiones = selectedData;
        if (selectedData.length > 1) {
            setTextWarn(`¿Seguro deseas marcar como facturadas las siguientes remisiones?`);
            setTextRemision(remisiones.map(remision => remision.alegraNumeracion).join(', '));
            setTextWarn2(`¿Seguro deseas anular las siguientes remisiones?`);
            setTextRemision2(remisiones.map(remision => remision.alegraNumeracion).join(', '));
        }else if(remisiones.length === 1){
            setTextWarn(`¿Seguro deseas marcar como facturada la siguiente remisión?`);
            setTextRemision(remisiones[0].alegraNumeracion);
            setTextWarn2(`¿Seguro deseas anular la siguiente remisión?`);
            setTextRemision2(remisiones[0].alegraNumeracion);
        }

    }, [selectedIds, data ]);

    const { data: clientes, isLoading } = useGetList('clientes', {filter: {id:{eq:selectedData[0]?.clienteId}}, meta: {searchable: 'true'}}, {enabled: isSameCliente&&isSameVendedor});

    useEffect(() => {
        if( selectedData.length > 30){
            notify('Solo puedes facturar 30 remisiones máximo', { type: 'error' }, { smart_count: 1 });
        }
        if (selectedData.length > 0 && selectedData.length <= 30 && isSameCliente && isSameVendedor && !isLoading) {
            const user = localStorage.getItem('user')
            const productFact = selectedData.flatMap(remision => remision.productFact.items);
            const remisionesIDs = selectedData.map(remision => remision.id);
            //console.log("selectedData[0]", selectedData[0])

            const objState = {
                record: {
                    usuarioID: user,
                    clienteId: selectedData[0]?.clienteId || null,
                    vendedorAlegraId: selectedData[0]?.vendedorAlegraId || null,
                    vendedorID: selectedData[0]?.vendedorID || null,
                    ciudadId : selectedData[0]?.ciudadId || null,
                    applyIva : selectedData[0]?.applyIva || null,
                    clienteName: selectedData[0]?.clienteName || null,
                    clientData: clientes && clientes.length ? clientes[0] : null,
                    alegraClienteId: selectedData[0]?.alegraClienteId || null,
                    productFact: {items: productFact},
                    formaPago: clientes && clientes.length ? clientes[0].datosFacturacion.formaPago : null,
                    remisionesIDs: remisionesIDs,
                    itemsFact: [],
                    emails: clientes && clientes.length ? clientes[0].datosFacturacion.emailFE.replaceAll(' ','').split(',') : [],

                }
            }
            console.log('INITIAL STATE', objState);

            setInitialState (objState)

        }
    }, [selectedIds, isSameCliente, isSameVendedor, isLoading]);


    return(
        <TopToolbar >
            <BulkUpdateButton label="Marcar Facturada"   icon={<CheckIcon/>} mutationMode='pessimistic' data={{ estado: "Facturada" }} confirmTitle='Marcar Remisión como Facturada' confirmContent={<span>{textWarn} <br/> <b>{textRemision}</b> <br/> Esta acción no se puede deshacer </span>}/>
            {/* <BulkUpdateButton label="Marcar Editada"   icon={<CheckIcon/>} mutationMode='pessimistic' data={{ estado: "Emitida" }} confirmTitle='Marcar Remisión como Emitida' confirmContent={<span>{textWarn} <br/> <b>{textRemision}</b> <br/> Esta acción no se puede deshacer </span>}/> */}
            <BulkUpdateButton label="Anular" color='error' icon={<BlockIcon/>} mutationMode='pessimistic' data={{ estado: "Anulada" }} confirmTitle='Anular Remisión' confirmContent={<span>{textWarn2} <br/> <b>{textRemision2}</b> <br/> Esta acción no se puede deshacer </span>}/>
            { areAllEmitidas ? <CreateButton label="Crear Factura" resource="facturas" disabled={ !isSameCliente || !isSameVendedor || isLoading  || !initialState } state={initialState} variant='outlined'/>:<></>}
            { areInvoiced ? <ImportInvoice state = {initialState} remisiones={selectedData} isSameCliente={isSameCliente} isSameVendedor={isSameVendedor}/>:<></>}
        </TopToolbar>
    );
}






// const FilterSideBar = (props) => {
//     const { data, filterValues, setFilters } = useListContext();

//     const unselectAll = useUnselectAll('sqlSalidas');

 
//     useEffect(() => {
//         if (filterValues) {
//             unselectAll()
//             }
//         else{
//         }
//     }, [filterValues, unselectAll]);
//     const [innerFilter, setInnerFilter] = React.useState('');
//     const handleInnerFilter = (e) => {
//         setInnerFilter(e.target.value);
//     };

//     return (
//     <Card sx={{ order: -1, mr: 2, mt: 9, width: 250 }}>
//         <CardContent>  
//         {
//         data&&data.length===0?
//             <Button onClick={()=>setFilters({})}>Limpiar Filtros</Button>
//         :(
//         <>
//             <FilterList label="Numero"  alwaysOn format={v=>v?.replaceAll('*', '')||''}>
//                 <FilterLiveSearch label="Numero" source="alegraNumeracion.wildcard" alwaysOn />
//             </FilterList>
//         </>)}
//         </CardContent>
//     </Card>
//     )
// }


export default RemisionList;